import React from "react";
import styled from "styled-components";
// Components
// import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
// import ProjectImg1 from "../../assets/img/projects/1.png";
// import ProjectImg2 from "../../assets/img/projects/2.png";
// import ProjectImg3 from "../../assets/img/projects/3.png";
// import ProjectImg4 from "../../assets/img/projects/4.png";
// import ProjectImg5 from "../../assets/img/projects/5.png";
// import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.png";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font40 extraBold">Top Of The Industry</h2>
              <p className="font12">
                Union Guard Capital is a distinguished investment firm
                specializing in asset management, dedicated to safeguarding and
                enhancing wealth through meticulous financial strategies.
                Founded in the UK, we bring a legacy of stability and regulatory
                compliance to our global operations. As a certified legal
                private company, we uphold professionalism and client security
                standards. We offer a comprehensive range of investment options
                tailored to meet the diverse needs of our clients, emphasizing
                stability and sustainability. At Union Guard Capital, we
                differentiate ourselves by embracing innovative solutions,
                including using cryptocurrency for deposits and withdrawals.
                This forward-thinking approach allows us to provide our
                investors flexibility and convenience, leveraging blockchain
                technology's benefits while maintaining rigorous security
                protocols. Our experienced team of professionals combines
                extensive market knowledge with a commitment to delivering
                exceptional results. We prioritize transparency, integrity, and
                the long-term prosperity of our clients, offering proactive
                asset management strategies that capitalize on opportunities
                while managing risks effectively. Whether you seek capital
                growth, income generation, or portfolio diversification, Union
                Guard Capital is your trusted partner in navigating the
                complexities of the financial landscape. Join us in achieving
                your financial goals with confidence and innovation.
              </p>
              <ButtonsRow
                className="flexNullCenter"
                style={{ margin: "30px 0" }}
              >
                <div style={{ width: "190px" }}>
                  <a href="https://app.unionguardcapital.com/signup">
                    {" "}
                    <FullButton title="Get Started" action={() => {}} />
                  </a>
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <a
                    href="mailto:contact@unionguardcapital.com?subject=Inquiry"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FullButton title="Contact Us" action={() => {}} border />{" "}
                  </a>
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
// const HeaderInfo = styled.div`
//   @media (max-width: 860px) {
//     text-align: center;
//   }
// `;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
