import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I’ve tried several investment platforms, but this one stands out. The customer service is excellent, and I appreciate the regular updates about my portfolio. Highly reliable and trustworthy!"
            author="Liam T."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="A fantastic platform for anyone looking to grow their finances. The automated investment options are a lifesaver, and the returns have been great so far. Highly recommended!"
            author="Sophia A."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I’m so glad I found this site! The platform is user-friendly, even for someone like me who is new to investing. The guided investment plans are well thought out and easy to follow."
            author="Michael B."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I’ve been using this platform for a year, and I couldn’t be happier. The insights provided are invaluable, and the transparency they offer builds trust. My portfolio has grown steadily!"
            author="Olivia H."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="The best investment site I’ve come across. Their tools make it easy to diversify my portfolio, and the performance tracking is detailed and accurate. Great for both beginners and seasoned investors."
            author="James C."
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="I love how intuitive this platform is. The mobile app works flawlessly, and I can check my investments on the go. Their support team is also very responsive and helpful."
            author="Ava P."
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
