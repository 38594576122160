import React from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { Spinner } from "reactstrap";
import { API } from "../..";
import { Element } from "react-scroll";

// Components
import PricingTable from "../Elements/PricingTable";

export default function Pricing() {
  // const API = axios.create({
  //   baseURL: "https://ugc-api-production.up.railway.app",
  // });

  const GetPlans = async () => {
    const response = await API.get("/plans");
    return response?.data?.data;
  };
  const { data: plansData, isLoading } = useQuery("plans", GetPlans);

  return (
    <Wrapper id="pricing">
      <Element name="pricing">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Choose a plan</h1>
              <p className="font13">
                Choose from our cutting edge array of investment plan options
              </p>
            </HeaderInfo>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner size={"xl"} color={"primary"} />
              </div>
            ) : (
              <TablesWrapper className="flexSpaceNull">
                {plansData !== undefined &&
                  plansData?.records?.length > 0 &&
                  plansData?.records?.map((item) => (
                    <TableBox key={item.id}>
                      <PricingTable
                        icon="browser"
                        min_deposit={`$${item.min_deposit}`}
                        max_deposit={`$${item.max_deposit}`}
                        title={item.name}
                        offers={[
                          {
                            name: `Min-Deposit: $${item.min_deposit}`,
                            cheked: true,
                          },
                          {
                            name: `Max-Deposit: $${item.max_deposit}`,
                            cheked: true,
                          },
                          {
                            name: `Duration: ${item.duration_days}days`,
                            cheked: true,
                          },
                          {
                            name: `Daily Profit: ${item.daily_profit}%`,
                            cheked: true,
                          },
                          {
                            name: `Cumulative Profit: ${item.cumulative_profit}%`,
                            cheked: true,
                          },
                          { name: "Capital Back: yes", cheked: true },
                        ]}
                        action={() => {}}
                      />
                    </TableBox>
                  ))}
              </TablesWrapper>
            )}
          </div>
        </div>
      </Element>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
